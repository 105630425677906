'use client';

import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    CURRENCY_RUB,
    formatAmountWithPostfix,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import LeadForm from '@app/components/LeadForm/LeadForm';
import BecomeBorrowerForm from '@app/features/borrowers/BecomeBorrowerForm/BecomeBorrowerForm';
import { accountStatusesHandler } from '@app/ducks/account';

/**
 * Лид-форма Заемщика в контенте поста
 */
export default function BorrowersLeadForm() {
    const [ statuses ] = useSimpleApiHandler(accountStatusesHandler);
    const limit = statuses?.[statuses?.length - 1]?.max_amount;

    return (
        <LeadForm
            title={`Быстрая проверка условий и лимит до ${formatAmountWithPostfix(limit, 0, CURRENCY_RUB, false)}`}
            leadForm={<BecomeBorrowerForm buttonText="Зарегистрироваться" type="form" />}
        />
    );
};