'use client';

import React from 'react';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';
import BecomeInvestorForm from '../BecomeInvestorForm/BecomeInvestorForm';
import LeadForm from '@app/components/LeadForm/LeadForm';

/**
 * Лид-форма Инвестора в контенте поста
 */
export default function InvestorsLeadForm() {
    const [stats] = useSimpleApiHandler(investorsJetLendStatisticsHandler);
    const cashbackRate = stats?.welcome_cashback_rate;

    return (
        <LeadForm
            title={
                `Станьте инвестором сейчас и получите повышенный кэшбек ${formatPercentExt(cashbackRate, {
                    fractionDigits: 0,
                    addSpace: false,
                    defaultValue: '10%',
                })}
            `}
            leadForm={(
                <BecomeInvestorForm
                    sectionType="lead-form"
                    buttonText="Зарегистрироваться"
                    type="short"
                />
            )}
        />
    );
};