'use client';

import React, {
    useState,
    useCallback,
} from 'react';
import Expand from 'react-expand-animated';
import iconArrow from '@frontend/jetlend-assets/icons/chevron-down.svg';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './expandableItem.module.scss';
import Image from '@app/components/Image/Image';

const ANIMATION_DURATION_MS = 400;

export interface IProps extends React.PropsWithChildren {
    /**
     * Заголовок
     */
    title: React.ReactNode;
    /**
     * Значение открытого состояния
     */
    expanded?: boolean;
    /**
     * Значение отключенного состояния
     */
    disabled?: boolean;
    /**
     * Значение тени
     */
    shadow?: boolean;
    /**
     * Класс для контейнера
     */
    className?: string;

    /**
     * Обработчик изменения состояния открытия
     * @param expanded - состояние открытия
     */
    onChange?: (expanded: boolean) => void;
}

/**
 * Компонент, отвечающий за раскрытие/скрытие контента
 */
export default function ExpandableItem({
    title,
    disabled,
    children,
    onChange,
    expanded,
    shadow,
    className,
}: IProps) {
    const [ownExpanded, setOwnExpanded] = useState<boolean>(false);
    const isOwnHandling = typeof onChange === 'undefined';

    /**
     * Внутренний обработчик нажатия кнопки
     */
    const didOwnClicked = useCallback(() => {
        setOwnExpanded(!ownExpanded);
    }, [ownExpanded, setOwnExpanded]);

    /**
     * Внешний обработчик нажатия кнопки
     */
    const didClicked = useCallback(() => {
        if (onChange) {
            onChange(!expanded);
        }
    }, [expanded, onChange]);

    const isExpanded = isOwnHandling ? ownExpanded : expanded;

    const iconClassName = buildClassNames(styles, [
        'button__icon',
        isExpanded && 'button__icon--open',
        (disabled) && 'button__icon--disabled',
    ]);

    const titleClassName = buildClassNames(styles, [
        'button__title',
        isExpanded && 'button__title--active',
    ]);

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            shadow && 'container--shadow',
        ]),
        className,
    ]);

    return (
        <div className={containerClassName}>
            <button
                className={styles['button']}
                type="button"
                onClick={isOwnHandling ? didOwnClicked : didClicked}
                disabled={disabled}
            >
                <span className={titleClassName}>
                    {title}
                </span>
                <span className={iconClassName}>
                    <Image src={iconArrow} alt="" />
                </span>
            </button>
            <Expand duration={ANIMATION_DURATION_MS} open={isExpanded}>
                <div className={styles['content']}>
                    {children}
                </div>
            </Expand>
        </div>
    );
};
