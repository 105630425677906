'use client';

import React from 'react';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import Link from 'next/link';
import backgroundIcon from '@app/icons/lead-form-background.svg';

import styles from './leadForm.module.scss';

export interface IProps {
    /**
     * Текст формы
     */
    title: string;
    /**
     * Фома регистрации
     */
    leadForm?: React.ReactNode;
}

/**
 * Лид-форма в контенте поста
 */
export default function LeadForm({
    title,
    leadForm,
}: IProps) {
    const backgroundImage = `url(${getAssetSrc(backgroundIcon)})`;

    return (
        <div className={styles['container']} style={{ backgroundImage }}>
            <div className={styles['content']}>
                <div className={styles['title']}>
                    {title}
                </div>
                {leadForm && (
                    <div className={styles['form']}>
                        {leadForm}
                    </div>
                )}
                <div className={styles['privacy-block']}>
                    <div>
                        Ввод номера телефона подтверждает ваше согласие с{' '}
                        <Link href="/doc-view/rules" target="_blank">правилами платформы</Link>{' '}
                        и&nbsp;
                        <Link href="/doc-view/privacy" target="_blank">
                            политикой&nbsp;обработки персональных данных
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};